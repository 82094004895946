import {
  FooterContainer,
  FooterLogo,
  FooterTitle,
  FooterCopyright,
} from "../styles/styles";

import logo from "../assets/images/fgjtam.png";

const Footer = () => {
  return (
    <FooterContainer>
      <div className="flex items-center justify-center">
        <FooterLogo>
          <img
            src={logo}
            alt="Logo de la Fiscalía General de Justicia del Estado de Tamaulipas"
          />
        </FooterLogo>
        <FooterTitle>Registro de huellas dactilares</FooterTitle>
        <FooterCopyright>FGJ Tamaulipas</FooterCopyright>
      </div>
    </FooterContainer>
  );
};

export default Footer;
