import {
  HeaderContainer,
  HeaderCenteredItems,
  HeaderLogo,
  HeaderTitle,
  HeaderSubtitle,
} from "../styles/styles";

import logo from "../assets/images/fgjtam.png";

const Header = () => {
  return (
    <HeaderContainer>
      <div className="row flex flex-column justify-center items-center">
        <HeaderLogo>
          <img
            src={logo}
            alt="Logo de la Fiscalía General de Justicia del Estado de Tamaulipas"
          />
        </HeaderLogo>
      </div>

      <HeaderCenteredItems>
        <HeaderSubtitle>Sistema de Servicios Periciales</HeaderSubtitle>
        <HeaderTitle>Registro de huellas dactilares</HeaderTitle>
      </HeaderCenteredItems>
    </HeaderContainer>
  );
};

export default Header;
