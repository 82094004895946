import { ChangeEventHandler, ChangeEvent, useState } from "react";
import axios from "axios";
import { Point } from "react-easy-crop/types";

// Componentes
import ImageCropper from "./components/ImageCropper";
import FilePicker from "./components/FilePicker";
import Header from "./components/Header";
import Footer from "./components/Footer";

// Styles
import {
  UploadTitle,
  UploadSubitle,
  PrintText,
  CTAButton,
  UploadAndCrop,
} from "./styles/styles";

// Const
import { huellas as HUELLAS } from "./constants";

const App = () => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [isUploading, setIsUploading] = useState(false);
  const [imageURLs, setImageURLs] = useState<string[]>([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [imageList, setImageList] = useState(HUELLAS);
  const [error, setError] = useState<Error>();
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [validMessage, setValidMessage] = useState("");
  const [nuc, setNuc] = useState<string>("");

  const addCroppedImage = (croppedImage: string) => {
    const newImages = [...imageList];

    newImages[selectedImageIndex] = {
      ...newImages[selectedImageIndex],
      url: croppedImage,
    };
    setImageList(newImages);
  };

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedImageIndex(parseInt(e.target.value, 10));
  };

  const uploadCroppedImages = async () => {
    setIsUploading(true);
    setError(undefined);

    try {
      const formData = new FormData();
      const imageListExport = imageList.filter((img) => img.url);

      // Map images to array of axios.post Promises
      if (imageListExport.length > 0) {
        // Add NUC to the body
        formData.append("nuc", nuc);

        for (let i = 0; i < imageListExport.length; i++) {
          const fileUrl = imageListExport[i].url || "";
          const huella = imageListExport[i].huella;
          const response = await fetch(fileUrl);
          const blob = await response.blob();

          formData.append("file", blob, `${huella}.${blob.type.split("/")[1]}`);
        }

        const response = await axios.post(
          "https://huellasdactilares.datacivica.org/api/v1/fingerprint",
          // "http://localhost:5000/api/v1/fingerprint",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data; application/octet-stream",
            },
            responseType: "blob",
          }
        );

        const filename = response.headers["content-disposition"]
          .split(";")[1]
          .trim()
          .split("=")[1];

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const button = document.createElement("button");
        button.classList.add("my-4");
        const link = document.createElement("a");
        link.innerHTML = "Descargar últimas imágenes guardadas";
        link.href = url;
        link.classList.add("download");
        link.setAttribute("download", filename); //or any other extension
        button.appendChild(link);

        if (link) {
          link.click();
        }

        button.remove();
      }
    } catch (error) {
      console.error(error);
      setError(error as Error);
    } finally {
      setIsUploading(false);
    }
  };

  const uploadPdf = async (pdfFile: File | null | undefined) => {
    setIsFileUploading(true);

    try {
      const formData = new FormData();
      // Map images to array of axios.post Promises

      if (pdfFile) {
        const fileURLs: string[] = [];
        fileURLs.push(URL.createObjectURL(pdfFile));

        for (let i = 0; i < fileURLs.length; i++) {
          const response = await fetch(`${fileURLs}`);
          const blob = await response.blob();

          formData.append(
            "file",
            blob,
            `${pdfFile.name}.${blob.type.split("/")[1]}`
          );
        }

        const response = await axios.post(
          // "http://localhost:5000/api/v1/pdf/conversion",
          "https://huellasdactilares.datacivica.org/api/v1/pdf/conversion",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data; application/octet-stream",
            },
            responseType: "blob",
          }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        setImageURLs([`${url}`]);

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } catch (error) {
      console.error(error);
      setError(error as Error);
    } finally {
      setIsFileUploading(false);
    }
  };

  const onFileChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setValidMessage(" ");
    const filePdf = event.target.files?.item(0);
    const fileType = filePdf?.type;
    const validPdfTypes = ["application/pdf"];

    if (validPdfTypes.includes(`${fileType}`)) {
      uploadPdf(filePdf);
    } else {
      setValidMessage("Sólo se aceptan PDFs");
    }
  };

  return (
    <div className="App flex flex-col h-screen" data-theme="light">
      <Header />
      <UploadAndCrop>
        {imageURLs.length === 0 ? (
          <div className="flex flex-col justify-center">
            <UploadTitle>
              ¿De qué documento se harán los recortes de huellas?
            </UploadTitle>
            <UploadSubitle>
              Se utilizará una documento PDF como fuente y se harán recortes de
              las áreas necesarias sobre éste.
            </UploadSubitle>
            <div className="flex flex-col items-center justify-center">
              <div className="flex flex-col gap-3 justify-center w-full items-center">
                <div className="form-control w-full max-w-md">
                  <label className="label">
                    <span className="label-text text-white font-semibold">
                      Número de ficha (NUC):
                    </span>
                  </label>
                  <input
                    type="text"
                    value={nuc}
                    onChange={(e) => setNuc(e.target.value)}
                    placeholder="Escriba el número de ficha (NUC)"
                    className="input input-bordered w-full"
                  />
                </div>
                <FilePicker
                  disabled={nuc === ""}
                  onFileChange={onFileChange}
                  loading={isFileUploading}
                  label={"Seleccione un archivo:"}
                />
                {!validMessage && (
                  <p className="text-center flex justify-center items-center text-red-500">
                    {validMessage}
                  </p>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div>
            {!isFileUploading && (
              <>
                <UploadSubitle>
                  Carga de imagen fuente {">"} Recorte de huellas dactilares
                </UploadSubitle>
                <p className="text-white font-bold text-center text-xl pb-4 md:text-3xl">
                  Recorta las 10 huellas
                </p>
                <div className="flex flex-col gap-4 items-center justify-center mb-6">
                  <p className="text-white md:text-xl">
                    Número de ficha (NUC):{" "}
                    <span className="font-bold">{nuc}</span>
                  </p>
                  <div className="form-control w-full max-w-md pb-5">
                    <label className="label">
                      <span className="label-text text-white font-bold">
                        Selecciona la huella a recortar:
                      </span>
                    </label>
                    <select
                      onChange={handleSelectChange}
                      value={selectedImageIndex}
                      className="select select-bordered text-dark-blue"
                    >
                      {imageList.map((img, i) => (
                        <option key={img.huella} value={i}>
                          {img.huella}
                        </option>
                      ))}
                    </select>
                  </div>
                  <p className="text-white text-lg">
                    Arrastra la imagen para ubicar el recorte en la posición que
                    deseas y desliza la barra para ampliar o alejar la imagen:
                  </p>
                </div>
                {imageURLs.map((imageSrc, index) => (
                  <div
                    className="flex flex-col justify-center gap-6"
                    key={imageSrc}
                  >
                    <ImageCropper
                      imageToCrop={imageSrc}
                      key={index}
                      crop={crop}
                      setCrop={setCrop}
                      onImageCropped={(croppedImage) => {
                        if (croppedImage) {
                          addCroppedImage(croppedImage);
                        }
                      }}
                    />
                  </div>
                ))}
                <p className="flex items-center justify-center py-5 text-white text-md md:text-xl">
                  Estás recortando:
                  <span className="pl-1 font-bold">
                    {imageList[selectedImageIndex].huella}
                  </span>
                </p>
                <div className="grid grid-cols-2 xs:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                  {imageList
                    .filter((i) => i.url)
                    .map((huella, index) => (
                      <div
                        className="min-w-[100px] flex flex-col w-full h-full"
                        key={index}
                      >
                        <PrintText>{huella.huella}</PrintText>
                        <img
                          className="min-w-full w-full md:min-h-[150px] max-h-[224px] p-3"
                          alt={`Recorte del dedo ${huella.huella}`}
                          src={huella.url}
                        />
                      </div>
                    ))}
                </div>
                <div
                  id="buttons"
                  className="flex flex-col justify-center w-full items-center mt-8"
                >
                  <p className="text-white mb-3 text-md md:text-xl">
                    Una vez que termines de recortar las imágenes, puedes
                    guardarlas:
                  </p>
                  <CTAButton
                    onClick={uploadCroppedImages}
                    disabled={!imageList.find((img) => img.url) || isUploading}
                    className={isUploading ? "loading" : undefined}
                  >
                    Guardar imágenes
                  </CTAButton>
                  {error && (
                    <p className="text-center flex justify-center items-center text-red-500">
                      Hubo un error al procesar tu solicitud. Error: "
                      {error.message}"
                    </p>
                  )}
                  <div className="h-6"></div>
                </div>
              </>
            )}
            <div className="md:flex sm:flex-wrap justify-center w-full items-center text-center mt-8">
              <p className="text-white mb-3 text-md md:text-xl">
                {isFileUploading
                  ? "Cargando el nuevo archivo..."
                  : "Tras guardar las imágenes, puedes cargar aquí un nuevo .PDF:"}
              </p>
              <div className="flex flex-col justify-center w-full items-center">
                <FilePicker
                  onFileChange={onFileChange}
                  loading={isFileUploading}
                  label={"Seleccione un nuevo archivo:"}
                />
                {!validMessage && (
                  <p className="text-center flex justify-center items-center text-red-500">
                    {validMessage}
                  </p>
                )}
              </div>
            </div>
          </div>
        )}
      </UploadAndCrop>
      <Footer />
    </div>
  );
};

export default App;
