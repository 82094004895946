import { FC, useEffect, useRef, useState } from "react";
import Cropper from "react-easy-crop";
import { Area } from "react-easy-crop/types";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

// Types
import { ImageCropperProps } from "@/types/props";

const MIN_ZOOM = 5;
const MAX_ZOOM = 13;
const ZOOM_STEP = 0.5;

const ImageCropper: FC<ImageCropperProps> = ({
  imageToCrop,
  onImageCropped = () => {},
  crop,
  setCrop,
}) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [zoom, setZoom] = useState(9);

  useEffect(() => {
    setCrop({ x: 0, y: 0 });
  }, [imageToCrop, setCrop]);

  async function cropImage(cropArea: Area) {
    if (imageRef.current) {
      const croppedImage = await getCroppedImage(imageRef.current, cropArea);
      onImageCropped(croppedImage);
    }
  }

  function getCroppedImage(sourceImage: HTMLImageElement, cropArea: Area) {
    // creating the cropped image from the source image
    const { x, y, width, height } = cropArea;
    const canvas = document.createElement("canvas");
    canvas.width = 416;
    canvas.height = 416;
    const ctx = canvas.getContext("2d");
    const scale = 416 / width;

    if (ctx && width > 0 && height > 0) {
      ctx.drawImage(
        sourceImage,
        x,
        y,
        width,
        height,
        0,
        0,
        width * scale,
        height * scale
      );
    }

    return new Promise<string>((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob || width === 0 || height === 0) {
            reject(new Error("Canvas is empty"));
            return;
          }

          // creating a Object URL representing the Blob object given
          const croppedImageUrl = window.URL.createObjectURL(blob);

          resolve(croppedImageUrl);
        },
        "image/png",
        1.0
      );
    });
  }

  return (
    <div className="w-full flex flex-col justify-center items-center gap-10">
      <div className="w-full flex flex-col items-center">
        <label
          className="text-white text-md p-4 antialiased md:text-xl"
          htmlFor="zoom"
        >
          Ampliar o alejar imagen
        </label>
        <div className="w-full flex flex-row items-center max-w-5xl">
          <button
            type="button"
            aria-label="Alejar imagen"
            className="flex justify-center items-center w-14 h-10 text-white font-bold text-3xl mr-4 antialiased rounded-full bg-light-blurple sm:w-11"
            onClick={() => {
              if (zoom > MIN_ZOOM) {
                setZoom(zoom - ZOOM_STEP);
              }
            }}
          >
            <AiOutlineMinus size={20} />
          </button>
          <input
            id="zoom"
            type="range"
            min={MIN_ZOOM}
            max={MAX_ZOOM}
            step={ZOOM_STEP}
            value={zoom}
            onChange={(e) => setZoom(parseFloat(e.target.value))}
            className="range range-primary"
          />
          <button
            type="button"
            aria-label="Ampliar imagen"
            className="flex justify-center items-center w-14 h-10 text-white font-bold text-3xl ml-4 antialiased rounded-full bg-light-blurple sm:w-11"
            onClick={() => {
              if (zoom < MAX_ZOOM) {
                setZoom(zoom + ZOOM_STEP);
              }
            }}
          >
            <AiOutlinePlus size={20} />
          </button>
        </div>
      </div>
      <div className="relative w-full h-[400px] md:h-[600px]">
        <Cropper
          image={imageToCrop}
          crop={crop}
          zoom={zoom}
          onZoomChange={setZoom}
          minZoom={MIN_ZOOM}
          maxZoom={MAX_ZOOM}
          zoomWithScroll
          aspect={1}
          onCropChange={setCrop}
          onCropComplete={(_area, areaPixels) => cropImage(areaPixels)}
          objectFit="auto-cover"
          setImageRef={(ref) => (imageRef.current = ref.current)}
        />
      </div>
    </div>
  );
};
export default ImageCropper;
